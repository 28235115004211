import React, { Fragment } from "react"
import Service from "src/components/section/service"
import Container from "src/components/container"
import SEO from "src/components/seo"

// const Contacts = () => (
//   <Fragment>
//     Техническая поддержка:
//     <br />
//     Тел: +7(000) 321-22-11 <br />
//     (с 10:00 до 18:00 МСК)
//   </Fragment>
// )

export default function Contact() {
  return (
    <Container>
      <SEO title="Контакты" />
      <section className="service h100">
        <div className="container">
          <Service
            title="Способы связи"
            // desc={<Contacts />}
            btn
            linkButton="https://t.me/novawardtech"
            descButton="Telegram"
          />
        </div>
      </section>
    </Container>
  )
}
